import { useEffect } from 'react';
import './App.css';

import { Footer } from './components/Footer/Footer';
import { BootstrapNavbar } from './components/BootstrapNavbar/BootstrapNavbar';
import { InteractiveCard } from './components/InteractiveCard/InteractiveCard';
import { ThumbnailCarousel } from './components/ThumbnailCarousel/ThumbnailCarousel';
import 'aos/dist/aos.css'
import Aos from 'aos'
import { GroupThumbnail } from './components/GroupThumbnail/GroupThumbnail';
import { ContactForm } from './components/ContactForm/ContactForm';

function App() {

  useEffect (() => {
        Aos.init({disable: 'mobile'});
        Aos.refresh({})
    }, []);

  return (
    <>
    <div className="App">
      <BootstrapNavbar />
      <ThumbnailCarousel className="h-100" id='langing' />
      <GroupThumbnail />


      <div className='services d-flex flex-column w-100 h-100' id='services'>
        <h1 className='justify-self-end align-self-center mt-5' style={{color: 'white'}}>NAŠE USLUGE</h1>
        <div className='wrapper d-flex flex-row flex-wrap pt-5 pb-5 justify-content-evenly'>
          <InteractiveCard title={'PRAVNO SAVJETOVANJE'} desc={'Svjesni smo da svaki pravni odnos ima svoje finansijske implikacije, pa ćemo vas savjetovati šta najbolje odgovara vašoj praksi.'}/>
          <InteractiveCard title={'POREZNO SAVJETOVANJE'} desc={'Savjetovanje o svim poreznim pitanjima radi osiguravanja zakonite, pravilne i optimalne primjene poreznih propisa, porezno planiranje i zastupanje pred poreznim tijelima.'}/>
          <InteractiveCard title={'FINANSIJSKO SAVJETOVANJE'} desc={'Usluga finansijskog savjetovanja pruža pomoć u navigaciji i oblikovanju komplikovanih finansijskih i tržišnih promjena.'}/>
          <InteractiveCard title={'ADMINISTRATIVNO SAVJETOVANJE'} desc={'Stručna procjena i postavljanje ciljeva, savjetovanje u brendiranju i rebranding'}/>
          <InteractiveCard title={'VIRTUAL OFFICE'} desc={'Društvima, kojima je potrebna poslovna adresa i koji su klijenti naše kompanije nudimo i najam usluge tzv. „virtuelne kancelarija“. Uz pomoć virtuelne kancelarije možete održati kontinuitet i kvalitet poslovanja. Osim registracije poslovne adrese na našoj adresi, nudimo vam i prijem, skeniranje, obavještavanje i slanje pošte, kao i druge usluge po potrebi.'}/>
          <InteractiveCard title={'RAČUNOVODSTVENE USLUGE'} desc={'Pomoć pri izvedbi financijskog i poslovnog restrukturiranja što uključuje mjere izvanparničnog (dobrovoljnog) restrukturiranja i restrukturirajućih mjera temeljem zakona o stečaju (preventivno restrukturiranje, prisilno rješevanje sporova).'}/>
          <InteractiveCard title={'IT SAVJETOVANJE'} desc={'Pobjednička digitalna strategija balansira današnje potrebe sa sutrašnjim prilikama.'}/>
          {/* <InteractiveCard title={'BIZNIS STRATEGIJA'} desc={'Kako napredovati velikom brzinom uprkos ogromnoj neizvjesnosti?'}/> */}
        </div>
      </div>



      <div className='d-flex flex-column aventus-bg'>
        <div className="d-flex flex-row main pt-5 pb-5 flex-end" id='info'>
          {/* <div className="d-flex flex-column col-4 mb-4 disappearing-div">
          </div> */}
            <div className="d-flex flex-column col-11 col-md-11 col-lg-8 mb-4 m-auto m-md-auto m-lg-auto">
              <h1 className="small-title">KONTAKT INFO</h1>
              <hr style={{background: "##ffffff"}} />

              <div className="infom" data-aos='fade-up'>
                  <p>Kontaktirajte nas putem telefona</p>
                  <p style={{color: "#ffffff", fontSize: "25px"}}>+387 63 886 467</p>
              </div>

              <div className="infom" data-aos="fade-up">
                  <p>Kontaktirajte nas putem email-a</p>
                  <p style={{color: "#ffffff", fontSize: "25px"}}>info@aventus.ba</p>
              </div>

              <div className="infom" data-aos="fade-up">
                  <p>Posjetite nas na našoj adresi</p>
                  <p style={{color: "#ffffff", fontSize: "25px"}}>Bosne Srebrene 60, Tuzla 75000</p>
              </div>
          </div>
        </div>
        
        {/* <div className="container2 d-flex flex-row thumbnail-2">
            <h1 className="small-title text-center m-auto">It’s not the destination, it's the journey.</h1>
        </div> */}

        <div className='contact pt-5 pb-5 col-12' id="contact">
          <ContactForm />
        </div>
      </div>
      
      <Footer />
    </div>
    </>
  );
}

export default App;
