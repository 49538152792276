import './navbar.css'
import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap';

export const BootstrapNavbar = () => {

  return (
  <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className='top navbar-top'>
    <Container>
    <Navbar.Brand href="/">
      <img className="navbar-image " src="/logo.webp" alt="aventus logo"></img>
      <h2 className='ps-3 m-0 navbar-font'>AVENTUS</h2>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav"  className="d-flex flex-row justify-content-end">

      <Nav className="mw-auto">
        <Nav.Link href="/">POČETNA</Nav.Link>
        <Nav.Link href="#services">USLUGE</Nav.Link>
        <Nav.Link href="#info">INFO</Nav.Link>
        <Nav.Link href="#contact">KONTAKT</Nav.Link>
      </Nav>

    </Navbar.Collapse>
    </Container>
  </Navbar>
  )} 