import React from 'react'
import { useForm, ValidationError } from '@formspree/react';

export const ContactForm = () => {
  const [state, handleSubmit] = useForm("xzbwgkew");
  
  return (
    <form className='col-11 col-md-8 col-lg-7 m-auto d-flex flex-column contact-form p-4' onSubmit={handleSubmit}>
        <h2>Kontaktirajte nas odmah</h2>
            <label htmlFor="email" className='mt-3'>
              E-mail
            </label>
            <input
              id="email"
              type="email" 
              name="email"
              className='mt-2 p-2 round-edges'
            />
            <ValidationError 
              prefix="Email" 
              field="email"
              className='mt-2'
              errors={state.errors}
            />

            <label htmlFor="company" className='mt-3'>
              Firma
            </label>
            <input
              id="company"
              type="text" 
              name="company"
              className='mt-2 p-2 round-edges'
            />
            <ValidationError 
              prefix="company" 
              field="company"
              className='mt-2'
              errors={state.errors}
            />

            <label htmlFor="message" className='mt-3'>
              Poruka
            </label>
            <textarea
              className='mt-2 pt-4 pb-4 round-edges'
              id="message"
              name="message"
            />
            <ValidationError 
              className='mt-3'
              prefix="Message" 
              field="message"
              errors={state.errors}
            />

            <button className='mt-3 contact-btn p-2 w-50 m-auto' type="submit" disabled={state.submitting}>
              Pošalji
            </button>
          </form>
  )
}
