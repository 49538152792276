import React from 'react'

export const Footer = () => {
  return (
    <footer className="pt-5 pb-5 d-flex flex-row col-12 justify-content-center" id="footer">
        <div className="col-10 col-md-6 col-lg-6 d-flex flex-row align-items-center responsive-div">
            <img className="footer-img col-4 img-fluid w-25" src="/logo.webp" alt="aventus logo"></img>
            <h1 className="col-4 logo-text footer-logo-text">AVENTUS</h1>
        </div>
        <div className="d-flex flex-column m-auto">
            <div className="col-12 d-flex flex-column m-auto center">
                <h4>Kontakt</h4>
                <p>Adresa: Bosne Srebrene 60, Tuzla 75000</p>
                <p>Tel: +387 63 886 467</p>
                <p>E-mail: info@aventus.ba</p>
            </div>
        </div>
    </footer>
  )
}
