import React from 'react'

export const GroupThumbnail = () => {
  return (
    <div className='col-12 row gx-0 group-thumbnail pb-5'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-row align-items-center justify-content-center'>
          {/* <a href="https://avnt.ba" target='_blank' className='col-7 d-flex flex-row align-items-center justify-content-center thumbnail-group-logo'><h1 class="col-12 text-center group-text mb-0 mt-3">AVNT</h1></a> */}
          <a href="https://avnt.ba" target='_blank' rel="noreferrer" className='col-7 d-flex flex-row align-items-center justify-content-center thumbnail-group-logo'><img className="col-12" src="/images/avnt_logo_white.webp" alt="AVNT logo" /></a>
        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-row align-items-center justify-content-center'>
          <a href="https://comversum.com" target='_blank' rel="noreferrer" className='col-7 d-flex flex-row align-items-center justify-content-center thumbnail-group-logo'><img className="col-12" src="/images/comversum-logo.webp" alt="Comversum logo" /></a>
        </div>

        <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex flex-row align-items-center justify-content-center'>
          <a href="https://biznis24.ba" target='_blank' rel="noreferrer" className='col-7 d-flex flex-row align-items-center justify-content-center thumbnail-group-logo'><img className="col-12" src="/images/biznis24_logo.webp" alt="Biznis24 logo" /></a>
        </div>
    </div>
  )
}
