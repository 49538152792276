import React from 'react'
import { Carousel } from 'react-bootstrap';

export const ThumbnailCarousel = () => {
  return (
      <Carousel variant="light" style={{"height": "100vh", "marginTop": "0px"}} className="thumbnail-container">
        <Carousel.Item interval={5000}>
          <div className='thumbnail-image-1'></div>
          <Carousel.Caption>
            <a href="https://avnt.ba" target="_blank" rel="noreferrer">
              <h1 className="thumbnail-title" >AVNT</h1>
              {/* <p className="thumbnail-description">Sigurnost podataka na sljedećem nivou!</p> */}
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={5000} bsPrefix="carousel-item">
          <div className='thumbnail-image-2'></div>
          <Carousel.Caption>
            <a href="https://comversum.com" target="_blank" rel="noreferrer">
              <h1 className="thumbnail-title" >COMVERSUM</h1>
              {/* <p className="thumbnail-description">The Defender of your Business</p> */}
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item interval={5000}>
          <div className='thumbnail-image-3'></div>
          <Carousel.Caption>
            <a href="https://biznis24.ba" target="_blank" rel="noreferrer">
              <h1 className="thumbnail-title" >BIZNIS<span style={{color: 'orange'}}>24</span></h1>
              {/* <p className="thumbnail-description">Biznis non stop</p> */}
            </a>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
  )
}