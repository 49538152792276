import React from 'react';
import Tilt from 'react-parallax-tilt';

export const InteractiveCard = ({ title, desc}) => {
    return (
    <>
        <div className="cardWrapper me-3 m-3">
            <Tilt className="tilt" perspective={3000} gyroscope={true} scale={1.07} glareEnable={true} tiltMaxAngleX={11} tiltMaxAngleY={11} glareMaxOpacity={0.08} glareColor={'#1e90ff, #0084ffa2 '} glarePosition={'all'}>
                <div className="card p-4">
                    <h4 className='cardTitle'>{title}</h4>
                    <p className='cardDesc'>{desc}</p>
                </div>
            </Tilt>
        </div>
    </>
    )
}